<template>
  <div class="w-full">
    <tab-layout-router
      :route="'doctor'"
      :tabs="[
        { key: 'calendar', label: 'Calendario' },
        { key: 'patients', label: 'Pacientes' },
        { key: 'payments', label: 'Ingresos y Pagos' },
        // { key: 'settings', label: 'Configuración' },
        { key: 'profile', label: 'Editar Datos' },
        { key: 'help', label: 'Ayuda' },
      ]"
    />
  </div>
</template>

<script>
import TabLayoutRouter from '@/components/dashboard/TabLayoutRouter.vue';

export default {
  name: 'Doctor',
  components: {
    'tab-layout-router': TabLayoutRouter,
  },
};
</script>
